import { DateTime } from 'luxon';
import { DateTimeHook } from '../../data/dates';
import {
  LedgerAccountEventType,
  LedgerReportGroupField,
  LedgerReportResultGroupValueDetails,
  LedgerReportTimeResolution,
  LedgerReportValueDetails,
  LedgerReportValueField,
} from '../../generated/graphql';
import { LedgerReportStandardTimeframe } from './ledgerReportModel';
import { ReportPropertyType } from './reportHelpers';

export const possibleAssets = ['AAVE', 'ADA', 'AVAX', 'BTC', 'DOT', 'ETH', 'LINK', 'LTC', 'MATIC', 'SOL', 'UNI', 'XLM'];

export interface LedgerReportValueFieldDetails {
  field: LedgerReportValueField;
  label: string;
  description: string;
  type: ReportPropertyType;
  replacementField?: LedgerReportValueField;
  deprecated?: boolean;
}

const valueFieldDetails: LedgerReportValueFieldDetails[] = [
  {
    field: LedgerReportValueField.AllocatedFunds,
    label: 'Allocated Funds',
    description: 'The amount of USD that is allocated in all portfolios in the fund at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.AssetAmount,
    label: 'Asset Amount',
    description: 'The number of tokens of a particular asset held at the end of the given period.',
    type: ReportPropertyType.Number,
  },
  {
    field: LedgerReportValueField.AssetDeploymentPercent,
    label: 'Asset Deployment %',
    description:
      'The absolute value of the percentage of the total value of the portfolio that is invested in a particular asset. This calculation uses the Asset Size.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.AssetPortfolioPercent,
    label: 'Asset Portfolio %',
    description:
      'The percentage of the total value of the portfolio that is invested in a particular asset. This calculation uses the Asset Value, which can be negative.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.AssetPrice,
    label: 'Asset Price',
    description: 'The price of the asset at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.AssetSize,
    label: 'Asset Value Size',
    description: 'The absolute value of the Asset Value.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.AssetValue,
    label: 'Asset Value',
    description:
      'The value of the asset at the end of the period. Calculated by multiplying the Asset Amount by the Asset Price.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.AssetValueChange,
    label: 'Asset Value Change',
    description:
      'The amount the Asset Value changed from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientExpenses,
    label: 'LP Expenses',
    description: 'The cumulative amount of USD in expenses paid by the LP at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientExpensesChange,
    label: 'LP Expenses Change',
    description: 'The change in amount of USD in expenses paid by the LP during the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientFees,
    label: 'LP Fees',
    description: 'The cumulative amount of USD in fees paid by the LP at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientFeesChange,
    label: 'LP Fees Change',
    description: 'The change in amount of USD in fees paid by the LP during the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientFundPercent,
    label: 'LP Fund Percent',
    description:
      'The percentage of the total value of the fund that is invested by the LP. This calculation uses the Fund Value.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.ClientHoldings,
    label: 'LP Holdings',
    description: 'The amount of USD invested by the LP at the end of the given period.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.ClientInvestment,
  },
  {
    field: LedgerReportValueField.ClientInvestment,
    label: 'LP Investment',
    description: 'The amount of USD invested by the LP at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientManagementFees,
    label: 'LP Management Fees',
    description: 'The cumulative amount of USD in management fees paid by the LP at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientManagementFeesChange,
    label: 'LP Management Fees Change',
    description: 'The change in amount of USD in management fees paid by the LP during the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientPerformanceFees,
    label: 'LP Performance Fees',
    description: 'The cumulative amount of USD in performance fees paid by the LP at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientPerformanceFeesChange,
    label: 'LP Performance Fees Change',
    description: 'The change in amount of USD in performance fees paid by the LP during the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.ClientValue,
    label: 'LP Value',
    description:
      'The value of the LP at the end of the given period. This calculation uses Asset Values, Investments and other USD Amounts.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.NetValue,
  },
  {
    field: LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
    label: 'Crypto Market PnL %',
    description:
      'The cumulative percentage return of the crypto market based on the market price of the market assets.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.CryptoMarketPnlCumulativeValue,
    label: 'Crypto Market PnL',
    description: 'The cumulative return of the crypto market based on the market price of the market assets.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.CryptoMarketPnlRoiChange,
    label: 'Crypto Market PnL % Change',
    description:
      'The percentage change in the Crypto Market PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.CryptoMarketPnlValueChange,
    label: 'Crypto Market PnL Change',
    description:
      'The change in the Crypto Market PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.EquityMarketPnlCumulativeRoi,
    label: 'S&P500 Index PnL %',
    description: 'The cumulative percentage return of the S&P500 Index based on the market price of the market assets.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.EquityMarketPnlCumulativeValue,
    label: 'S&P500 Index PnL',
    description: 'The cumulative return of the S&P500 Index based on the market price of the market assets.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.EquityMarketPnlRoiChange,
    label: 'S&P500 Index PnL % Change',
    description:
      'The percentage change in the S&P500 Index PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.EquityMarketPnlValueChange,
    label: 'S&P500 Index PnL Change',
    description:
      'The change in the S&P500 Index PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.HighWaterMark,
    label: 'High Water Mark',
    description:
      'The highest value of the fund or LP value since the last time the performance fee was paid. This calculation uses the PnL.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.IdealClientFeesAdjustments,
    label: 'LP Fees (Idealized Adjustments)',
    description:
      'The amount of USD in additional fees that would have been paid by the LP at the end of the given period if they paid the standard fund LP fee rates.',
    type: ReportPropertyType.Usd,
    deprecated: true,
  },
  {
    field: LedgerReportValueField.ManagedCurrency,
    label: 'Allocated Funds',
    description:
      'The amount of USD that the portfolio is allocated for investing. When grouping by Asset and/or Long/Short, this value is the amount allocated to the individual asset or long/short position.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.MarketPnlCumulativeRoi,
    label: 'Market PnL %',
    description: 'The cumulative percentage return of the market based on the market price of the market assets.',
    type: ReportPropertyType.Percent,
    replacementField: LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
  },
  {
    field: LedgerReportValueField.MarketPnlCumulativeValue,
    label: 'Market PnL',
    description: 'The cumulative return of the market based on the market price of the market assets.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.CryptoMarketPnlCumulativeValue,
  },
  {
    field: LedgerReportValueField.MarketPnlRoiChange,
    label: 'Market PnL % Change',
    description:
      'The percentage change in the Market PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
    replacementField: LedgerReportValueField.CryptoMarketPnlRoiChange,
  },
  {
    field: LedgerReportValueField.MarketPnlValueChange,
    label: 'Market PnL Change',
    description: 'The change in the Market PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.CryptoMarketPnlValueChange,
  },
  {
    field: LedgerReportValueField.NetValue,
    label: 'Net Value',
    description:
      'The value of the LP, fund or portfolio at the end of the given period. This calculation uses Asset Values, Allocated Funds, Investments, and other USD Amounts.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.NumTrades,
    label: '# Trades',
    description: 'The cumulative number of trades executed by the end of the given period.',
    type: ReportPropertyType.Integer,
    replacementField: LedgerReportValueField.NumTradesCumulativeValue,
  },
  {
    field: LedgerReportValueField.NumTradesCumulativeValue,
    label: '# Trades',
    description: 'The cumulative number of trades executed by the end of the given period.',
    type: ReportPropertyType.Integer,
  },
  {
    field: LedgerReportValueField.NumTradesValueChange,
    label: '# Trades Change',
    description: 'The number of trades executed from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Integer,
  },
  {
    field: LedgerReportValueField.PeriodStartAt,
    label: 'Period Start At',
    description: 'The start of the period.',
    type: ReportPropertyType.DateTime,
  },
  {
    field: LedgerReportValueField.PeriodEndAt,
    label: 'Period End At',
    description: 'The end of the period.',
    type: ReportPropertyType.DateTime,
  },
  {
    field: LedgerReportValueField.PeriodNumber,
    label: 'Period Number',
    description: 'The period number.',
    type: ReportPropertyType.Integer,
  },
  {
    field: LedgerReportValueField.PnlCumulativeRoi,
    label: 'PnL %',
    description:
      'The cumulative percentage return of the LP, portfolio or fund based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlCumulativeIdealRoi,
    label: 'PnL % (Idealized)',
    description:
      'The cumulative percentage return of the LP or fund based on the market price of the assets in the portfolio including any realized gains or losses if all LPs had paid the standard fund LP fee rates.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlCumulativeInvestmentRoi,
    label: 'PnL % (Investment)',
    description:
      'The compounded cumulative percentage return over the investment of the LP, portfolio or fund based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlCumulativeMonthlyRoi,
    label: 'PnL % (Monthly)',
    description:
      'The cumulative percentage return over the net value at the beginning of the month for the LP or fund, based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlCumulativeQuarterlyRoi,
    label: 'PnL % (Quarterly)',
    description:
      'The cumulative percentage return over the net value at the beginning of the quarter for the LP or fund, based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlCumulativeUncrystallizedValue,
    label: 'Pnl (Uncrystallized)',
    description:
      'The cumulative return of the fund based on the market price of the assets in the portfolio including any realized gains or losses, without uncrystallized performance fees taken out.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.PnlCumulativeValue,
    label: 'PnL',
    description:
      'The cumulative return of the LP, portfolio or fund based on the market price of the assets in the portfolio including any realized gains or losses, with LP fees taken out as appropriate.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.PnlCumulativeYearlyRoi,
    label: 'PnL % (Yearly)',
    description:
      'The cumulative percentage return over the net value at the beginning of the year for the LP or fund, based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlLatestRoi,
    label: 'Latest PnL %',
    description:
      'The percentage return of the LP, portfolio or fund based on the market price of the assets in the portfolio including any realized gains or losses. This calculation uses the latest Allocated Funds value vs. accumulating the percentage return at the time of the trade.',
    type: ReportPropertyType.Percent,
    replacementField: LedgerReportValueField.PnlCumulativeRoi,
  },
  {
    field: LedgerReportValueField.PnlRoiChange,
    label: 'PnL % Change',
    description: 'The percentage change in the PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlIdealRoiChange,
    label: 'PnL % Change (Idealized)',
    description:
      'The percentage change in the PnL from the end of the previous period to the end of the given period if all LPs had paid the standard fund LP fee rates.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlInvestmentRoiChange,
    label: 'PnL % Change (Investment)',
    description:
      'The percentage change over the LP Investment in the PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlMonthlyRoiChange,
    label: 'PnL % Change (Monthly)',
    description:
      'The percentage change over the net value at the beginning of the month for the LP or fund, based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlQuarterlyRoiChange,
    label: 'PnL % Change (Quarterly)',
    description:
      'The percentage change over the net value at the beginning of the quarter for the LP or fund, based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PnlUncrystallizedValueChange,
    label: 'Pnl Change (Uncrystallized)',
    description:
      'The change in PnL (Uncrystallized) from the end of the previous period to the end of the given period',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.PnlValueChange,
    label: 'PnL Change',
    description: 'The change in the PnL from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.PnlYearlyRoiChange,
    label: 'PnL % Change (Yearly)',
    description:
      'The percentage change over the net value at the beginning of the year for the LP or fund, based on the market price of the assets in the portfolio including any realized gains or losses.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PortfolioFundPercent,
    label: 'Portfolio Fund %',
    description: 'The percentage of the total value of the fund that is invested in the given portfolio.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.PortfolioValue,
    label: 'Portfolio Value',
    description:
      'The value of the fund or portfolio at the end of the given period. This calculation uses Asset Values, Allocated Funds and other USD Amounts.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.NetValue,
  },
  {
    field: LedgerReportValueField.RealizedGainsCumulativeRoi,
    label: 'Realized Gains %',
    description:
      'The cumulative percentage return of the portfolio or fund based on sold assets. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.RealizedGainsCumulativeIdealRoi,
    label: 'Realized Gains % (Idealized)',
    description:
      'The cumulative percentage return of the portfolio or fund based on sold assets if all LPs had paid the standard fund LP fee rates. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.RealizedGainsCumulativeInvestmentRoi,
    label: 'Realized Gains % (Investment)',
    description:
      'The compounded cumulative percentage return over the LP Investment of the portfolio or fund based on sold assets. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.RealizedGainsCumulativeValue,
    label: 'Realized Gains',
    description:
      'The cumulative return of the portfolio or fund based on sold assets. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.RealizedGainsRoiChange,
    label: 'Realized Gains % Change',
    description:
      'The percentage change in the Realized Gains from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.RealizedGainsIdealRoiChange,
    label: 'Realized Gains % Change (Idealized)',
    description:
      'The percentage change in the Realized Gains from the end of the previous period to the end of the given period  if all LPs had paid the standard fund LP fee rates.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.RealizedGainsInvestmentRoiChange,
    label: 'Realized Gains % Change (Investment)',
    description:
      'The percentage change over the LP Investment in the Realized Gains from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.RealizedGainsValueChange,
    label: 'Realized Gains Change',
    description: 'The change in the Realized Gains from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.TurnoverCumulativeRoi,
    label: 'Turnover %',
    description:
      'The cumulative USD spent and received as a percentage of the portfolio or fund. This calculation uses the absolute value of the USD Amount.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.TurnoverCumulativeValue,
    label: 'Turnover',
    description:
      'The cumulative USD amounts spent and received in the portfolio or fund. This calculation uses the absolute value of the USD Amount.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.TurnoverRoiChange,
    label: 'Turnover % Change',
    description:
      'The USD amounts spent and received during the given period as a percentage of the portfolio or fund. This calculation uses the absolute value of the USD Amount.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.TurnoverValueChange,
    label: 'Turnover Change',
    description:
      'The USD amounts spent and received in the portfolio or fund during the given period. This calculation uses the absolute value of the USD Amount.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.TurnoverValue,
    label: 'Turnover All Time',
    description:
      'The USD amounts spent and received over all time. This calculation uses the absolute value of the USD Amount.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.TurnoverCumulativeValue,
  },
  {
    field: LedgerReportValueField.TurnoverPercent,
    label: 'Latest Turnover %',
    description:
      'The percentage of the total value of the portfolio at that is spent and received over all time. This calculation uses the absolute value of the USD Amount. This calculation uses the latest Allocated Funds value vs. accumulating the percentage change at the time of the trade.',
    type: ReportPropertyType.Percent,
    replacementField: LedgerReportValueField.TurnoverCumulativeRoi,
  },
  {
    field: LedgerReportValueField.UnallocatedFunds,
    label: 'Unallocated Funds',
    description:
      'The amount of USD that is not allocated in any portfolios in the fund at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.UnallocatedHoldings,
    label: 'Unallocated Holdings',
    description:
      'The amount of USD invested by LPs that is not invested in any portfolios in the fund at the end of the given period.',
    type: ReportPropertyType.Usd,
    replacementField: LedgerReportValueField.UnallocatedInvestment,
  },
  {
    field: LedgerReportValueField.UnallocatedInvestment,
    label: 'Unallocated Investment',
    description:
      'The amount of USD invested by LPs that is not invested in any portfolios in the fund at the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.UncrystallizedNetValue,
    label: 'NAV Account Balance',
    description:
      'The value of the fund at the end of the given period without uncrystallized performance fees taken out. This calculation comes from NAV reconciliation data.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.UnrealizedGainsCumulativeRoi,
    label: 'Unrealized Gains %',
    description:
      'The cumulative percentage return of the portfolio or fund based on current assets. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.UnrealizedGainsCumulativeInvestmentRoi,
    label: 'Unrealized Gains % (Investment)',
    description:
      'The compounded cumulative percentage return over the LP Investment of the portfolio or fund based on current assets. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.UnrealizedGainsCumulativeValue,
    label: 'Unrealized Gains',
    description:
      'The cumulative return of the portfolio or fund based on current assets. The calculation uses a Lowest-In First-Out (LIFO) method to determine the cost basis of the asset.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.UnrealizedGainsRoiChange,
    label: 'Unrealized Gains % Change',
    description:
      'The percentage change in the Unrealized Gains from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.UnrealizedGainsInvestmentRoiChange,
    label: 'Unrealized Gains % Change (Investment)',
    description:
      'The percentage change over the LP Investment in the Unrealized Gains from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Percent,
  },
  {
    field: LedgerReportValueField.UnrealizedGainsValueChange,
    label: 'Unrealized Gains Change',
    description:
      'The change in the Unrealized Gains from the end of the previous period to the end of the given period.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.UsdAmount,
    label: 'USD Amount',
    description: 'The total amount of USD spent or received.',
    type: ReportPropertyType.Usd,
  },
  {
    field: LedgerReportValueField.UsdBalance,
    label: 'USD Balance',
    description:
      'The amount of USD left in the portfolio or fund at the end of the given period. This calculation uses the USD Amount and the Allocated Funds or LP Investment.',
    type: ReportPropertyType.Usd,
  },
];

const valueFieldDetailsMap = new Map<LedgerReportValueField, LedgerReportValueFieldDetails>(
  valueFieldDetails.map((d) => [d.field, d]),
);

export const sortReportValueFields = (valueFields: LedgerReportValueField[]): LedgerReportValueField[] =>
  valueFields.sort((a, b) => determineFieldName(a).localeCompare(determineFieldName(b)));

export const reportFieldNotDeprecated = (valueField: LedgerReportValueField): boolean => {
  const LedgerReportValueFieldDetails = valueFieldDetailsMap.get(valueField);
  if (!LedgerReportValueFieldDetails) {
    return false;
  }

  return !LedgerReportValueFieldDetails.replacementField && !LedgerReportValueFieldDetails.deprecated;
};

export const replaceDeprecatedField = (valueField: LedgerReportValueField): LedgerReportValueField => {
  const LedgerReportValueFieldDetails = valueFieldDetailsMap.get(valueField);
  if (!LedgerReportValueFieldDetails) {
    return valueField;
  }

  if (!LedgerReportValueFieldDetails.replacementField) {
    return valueField;
  }

  return replaceDeprecatedField(LedgerReportValueFieldDetails.replacementField);
};

export const determineFieldName = (valueField: LedgerReportValueField, showDeprecated: boolean = false): string => {
  const ledgerReportValueFieldDetails = valueFieldDetailsMap.get(valueField);
  if (!ledgerReportValueFieldDetails) {
    return valueField;
  }

  if (!ledgerReportValueFieldDetails.replacementField && !ledgerReportValueFieldDetails.deprecated) {
    return ledgerReportValueFieldDetails.label;
  }

  if (!showDeprecated && ledgerReportValueFieldDetails.replacementField) {
    return determineFieldName(ledgerReportValueFieldDetails.replacementField);
  }

  return ledgerReportValueFieldDetails.label + ' (deprecated)';
};

export const determineFieldDescription = (
  valueField: LedgerReportValueField,
  showDeprecated: boolean = false,
): string => {
  const ledgerReportValueFieldDetails = valueFieldDetailsMap.get(valueField);
  if (!ledgerReportValueFieldDetails) {
    return '';
  }

  if (!ledgerReportValueFieldDetails.replacementField && !ledgerReportValueFieldDetails.deprecated) {
    return ledgerReportValueFieldDetails.description;
  }

  if (ledgerReportValueFieldDetails.replacementField) {
    if (!showDeprecated) {
      return determineFieldDescription(ledgerReportValueFieldDetails.replacementField);
    }

    return (
      'DEPRECATED: Please use ' +
      determineFieldName(ledgerReportValueFieldDetails.replacementField) +
      ' instead. ' +
      ledgerReportValueFieldDetails.description
    );
  }

  return 'DEPRECATED: ' + ledgerReportValueFieldDetails.deprecated;
};

export const determineFieldType = (valueField: LedgerReportValueField): ReportPropertyType => {
  const LedgerReportValueFieldDetails = valueFieldDetailsMap.get(valueField);
  if (!LedgerReportValueFieldDetails) {
    console.warn('Unknown field type for valueField:', valueField);
    return ReportPropertyType.Unknown;
  }

  return LedgerReportValueFieldDetails.type;
};

export const findLedgerReportGroupName = (
  groupValues: readonly LedgerReportResultGroupValueDetails[],
  group: LedgerReportGroupField,
) => {
  const value = groupValues.find((v) => v.field === group);
  if (!value) {
    return null;
  }

  return value.name;
};

export const findLedgerReportFieldValue = (
  fieldValues: readonly LedgerReportValueDetails[],
  field: LedgerReportValueField,
) => {
  const value = findLedgerReportFieldValueDetails(fieldValues, field);
  if (!value) {
    return undefined;
  }

  return extractLedgerReportFieldValue(value);
};

export const findLedgerReportFieldValueDetails = (
  fieldValues: readonly LedgerReportValueDetails[],
  field: LedgerReportValueField,
) => fieldValues.find((v) => v.field === field);

export const extractLedgerReportFieldValue = (value: LedgerReportValueDetails): number | string => {
  if ('numberValue' in value) {
    return value.numberValue;
  } else {
    return value.textValue;
  }
};

export const buildAccountEventText = (eventType: LedgerAccountEventType, value: string) => {
  switch (eventType) {
    case LedgerAccountEventType.Deployment:
      return determineAccountEventTypeName(eventType);
    case LedgerAccountEventType.Version:
      return value;
  }

  console.warn('Unknown account event type: ', eventType);
  return `${determineAccountEventTypeName(eventType)} ${value}`;
};

export const determineAccountEventTypeName = (eventType: LedgerAccountEventType) => {
  switch (eventType) {
    case LedgerAccountEventType.Deployment:
      return 'Deployment';

    case LedgerAccountEventType.Version:
      return 'Version';
  }

  console.warn('Unknown account event type: ', eventType);
  const eventTypeText = eventType as string;
  return eventTypeText.charAt(0).toUpperCase() + eventTypeText.slice(1).toLowerCase();
};

export const formatDateTime = (periodStartAt: DateTime, timeResolution: LedgerReportTimeResolution): string => {
  switch (timeResolution) {
    case LedgerReportTimeResolution.Year:
      return periodStartAt.toFormat('yyyy');

    case LedgerReportTimeResolution.Month:
      return periodStartAt.toFormat('MMM yyyy');

    case LedgerReportTimeResolution.Quarter:
      return periodStartAt.toFormat("'Q'q yyyy");

    case LedgerReportTimeResolution.Week:
    case LedgerReportTimeResolution.Day:
      return periodStartAt.toLocaleString(DateTime.DATE_SHORT);

    case LedgerReportTimeResolution.Hour:
      return periodStartAt.toLocaleString(DateTime.DATETIME_SHORT);

    default:
      console.warn('Unknown time resolution: ', timeResolution);
      return periodStartAt.toLocaleString(DateTime.DATETIME_SHORT);
  }
};

export const determineStartAtFromStandardTimeframe = (
  timeframe: LedgerReportStandardTimeframe,
  { now, toDateTime }: Pick<DateTimeHook, 'now' | 'toDateTime'>,
): DateTime => {
  switch (timeframe) {
    case LedgerReportStandardTimeframe.AllTime:
      return toDateTime('2022-01-01');

    case LedgerReportStandardTimeframe.Year:
      return now().minus({ days: 365 }).startOf('day');

    case LedgerReportStandardTimeframe.YearToDate:
      return now().startOf('year');

    case LedgerReportStandardTimeframe.PreviousYear:
      return now().minus({ years: 1 }).startOf('year');

    case LedgerReportStandardTimeframe.Quarter:
      return now().minus({ days: 90 }).startOf('day');

    case LedgerReportStandardTimeframe.QuarterToDate:
      return now().startOf('quarter');

    case LedgerReportStandardTimeframe.PreviousQuarter:
      return now().minus({ months: 3 }).startOf('quarter');

    case LedgerReportStandardTimeframe.Month:
      return now().minus({ days: 30 }).startOf('day');

    case LedgerReportStandardTimeframe.MonthToDate:
      return now().startOf('month').startOf('day');

    case LedgerReportStandardTimeframe.PreviousMonth:
      return now().minus({ months: 1 }).startOf('month');

    case LedgerReportStandardTimeframe.Week:
      return now().minus({ weeks: 1 }).startOf('hour');

    case LedgerReportStandardTimeframe.Day:
      return now().minus({ days: 1 }).startOf('hour');

    default:
      console.warn('Unknown standard timeframe:', timeframe);
      return now().minus({ weeks: 1 });
  }
};

export const determineEndAtFromStandardTimeframe = (
  timeframe: LedgerReportStandardTimeframe,
  { now }: Pick<DateTimeHook, 'now'>,
): DateTime | null => {
  switch (timeframe) {
    case LedgerReportStandardTimeframe.PreviousYear:
      return now().minus({ years: 1 }).endOf('year');

    case LedgerReportStandardTimeframe.PreviousQuarter:
      return now().minus({ months: 3 }).endOf('quarter');

    case LedgerReportStandardTimeframe.PreviousMonth:
      return now().minus({ months: 1 }).endOf('month');

    default:
      return null;
  }
};

export const determineTimeFromTimeResolution = (dateTime: DateTime, timeResolution: LedgerReportTimeResolution) => {
  switch (timeResolution) {
    case LedgerReportTimeResolution.Year:
      return dateTime.startOf('year');

    case LedgerReportTimeResolution.Quarter:
      return dateTime.startOf('quarter');

    case LedgerReportTimeResolution.Month:
      return dateTime.startOf('month');

    case LedgerReportTimeResolution.Week:
      return dateTime.startOf('week');

    case LedgerReportTimeResolution.Day:
      return dateTime.startOf('day');

    case LedgerReportTimeResolution.Hour:
      return dateTime.startOf('hour');

    default:
      console.warn('Unknown time resolution: ', timeResolution);
      return dateTime;
  }
};

export const timeResolutionValues = {
  [LedgerReportTimeResolution.Hour]: 1,
  [LedgerReportTimeResolution.Day]: 2,
  [LedgerReportTimeResolution.Week]: 3,
  [LedgerReportTimeResolution.Month]: 4,
  [LedgerReportTimeResolution.Year]: 5,
};

export const determineTimeResolutionFromDates = (
  startAt: DateTime,
  endAt: DateTime | null,
  timeResolution: LedgerReportTimeResolution,
  { now }: Pick<DateTimeHook, 'now'>,
) => {
  const duration = (endAt || now()).diff(startAt, 'days').toObject();

  let bestTimeResolution: LedgerReportTimeResolution;

  if (duration.days <= 15) {
    bestTimeResolution = LedgerReportTimeResolution.Hour;
  } else if (duration.days <= 365 * 2) {
    bestTimeResolution = LedgerReportTimeResolution.Day;
  } else {
    bestTimeResolution = LedgerReportTimeResolution.Month;
  }

  if (timeResolutionValues[timeResolution] <= timeResolutionValues[bestTimeResolution]) {
    return bestTimeResolution;
  }

  return timeResolution;
};

export const getGroupsValues = (groups: LedgerReportGroupField[]): LedgerReportValueField[] => {
  const groupsValues = ledgerReportGroupsData.find((groupWithValues) => {
    return (
      groupWithValues.groups.every((group) => groups.includes(group)) && groupWithValues.groups.length === groups.length
    );
  });

  if (groupsValues) {
    return groupsValues.values;
  }

  console.warn('Unknown groups:', groups);
  return [];
};

export const buildGroupValueDisplay = (group: string | null | undefined): string | undefined =>
  group?.replace(/^Portfolio -|^Client -/, '');

export interface LedgerReportGroupsData {
  name: string;
  groups: LedgerReportGroupField[];
  values: LedgerReportValueField[];
  deprecated?: boolean;
}

const commonAssetFields = [
  LedgerReportValueField.AssetCost,
  LedgerReportValueField.AverageAssetCost,
  LedgerReportValueField.AssetSize,
  LedgerReportValueField.AssetValue,
  LedgerReportValueField.AssetValueChange,
  LedgerReportValueField.NumTrades,
  LedgerReportValueField.NumTradesCumulativeValue,
  LedgerReportValueField.NumTradesValueChange,
];

const commonClientFields = [
  LedgerReportValueField.ClientDeposits,
  LedgerReportValueField.ClientExpenses,
  LedgerReportValueField.ClientExpensesChange,
  LedgerReportValueField.ClientFees,
  LedgerReportValueField.ClientFeesChange,
  LedgerReportValueField.ClientManagementFees,
  LedgerReportValueField.ClientManagementFeesChange,
  LedgerReportValueField.ClientPerformanceFees,
  LedgerReportValueField.ClientPerformanceFeesChange,
  LedgerReportValueField.ClientHoldings,
  LedgerReportValueField.ClientInvestment,
  LedgerReportValueField.ClientWithdrawals,
];

const commonPnlFields = [
  LedgerReportValueField.CryptoMarketPnlCumulativeRoi,
  LedgerReportValueField.CryptoMarketPnlCumulativeValue,
  LedgerReportValueField.CryptoMarketPnlRoiChange,
  LedgerReportValueField.CryptoMarketPnlValueChange,
  LedgerReportValueField.EquityMarketPnlCumulativeRoi,
  LedgerReportValueField.EquityMarketPnlCumulativeValue,
  LedgerReportValueField.EquityMarketPnlRoiChange,
  LedgerReportValueField.EquityMarketPnlValueChange,
  LedgerReportValueField.MarketPnlCumulativeRoi,
  LedgerReportValueField.MarketPnlCumulativeValue,
  LedgerReportValueField.MarketPnlRoiChange,
  LedgerReportValueField.MarketPnlValueChange,
  LedgerReportValueField.PnlCumulativeRoi,
  LedgerReportValueField.PnlCumulativeValue,
  LedgerReportValueField.PnlLatestRoi,
  LedgerReportValueField.PnlRoiChange,
  LedgerReportValueField.PnlValueChange,
  LedgerReportValueField.RealizedGainsCumulativeRoi,
  LedgerReportValueField.RealizedGainsCumulativeValue,
  LedgerReportValueField.RealizedGainsRoiChange,
  LedgerReportValueField.RealizedGainsValueChange,
  LedgerReportValueField.UnrealizedGainsCumulativeRoi,
  LedgerReportValueField.UnrealizedGainsCumulativeValue,
  LedgerReportValueField.UnrealizedGainsRoiChange,
  LedgerReportValueField.UnrealizedGainsValueChange,
];

const investmentPnlFields = [
  LedgerReportValueField.PnlCumulativeInvestmentRoi,
  LedgerReportValueField.PnlInvestmentRoiChange,
  LedgerReportValueField.RealizedGainsCumulativeInvestmentRoi,
  LedgerReportValueField.RealizedGainsInvestmentRoiChange,
  LedgerReportValueField.UnrealizedGainsCumulativeInvestmentRoi,
  LedgerReportValueField.UnrealizedGainsInvestmentRoiChange,
];

const datedPnlFields = [
  LedgerReportValueField.PnlCumulativeMonthlyRoi,
  LedgerReportValueField.PnlMonthlyBaseValue,
  LedgerReportValueField.PnlMonthlyRoiChange,
  LedgerReportValueField.PnlCumulativeQuarterlyRoi,
  LedgerReportValueField.PnlQuarterlyBaseValue,
  LedgerReportValueField.PnlQuarterlyRoiChange,
  LedgerReportValueField.PnlCumulativeYearlyRoi,
  LedgerReportValueField.PnlYearlyBaseValue,
  LedgerReportValueField.PnlYearlyRoiChange,
];

const uncrystallizedFields = [
  LedgerReportValueField.PnlCumulativeUncrystallizedValue,
  LedgerReportValueField.PnlUncrystallizedValueChange,
  LedgerReportValueField.UncrystallizedNetValue,
];

const idealPnlFields = [
  LedgerReportValueField.PnlCumulativeIdealRoi,
  LedgerReportValueField.PnlIdealRoiChange,
  LedgerReportValueField.RealizedGainsCumulativeIdealRoi,
  LedgerReportValueField.RealizedGainsIdealRoiChange,
];

const commonTurnoverFields = [
  LedgerReportValueField.TurnoverCumulativeRoi,
  LedgerReportValueField.TurnoverCumulativeValue,
  LedgerReportValueField.TurnoverRoiChange,
  LedgerReportValueField.TurnoverValueChange,
  LedgerReportValueField.TurnoverValue,
  LedgerReportValueField.TurnoverPercent,
];

const companyGroupFields = [
  LedgerReportValueField.AllocatedFunds,
  ...commonAssetFields,
  ...commonClientFields,
  ...commonPnlFields,
  ...datedPnlFields,
  ...investmentPnlFields,
  LedgerReportValueField.HighWaterMark,
  LedgerReportValueField.NetValue,
  LedgerReportValueField.PortfolioValue,
  ...commonTurnoverFields,
  LedgerReportValueField.UnallocatedFunds,
  LedgerReportValueField.UnallocatedHoldings,
  LedgerReportValueField.UnallocatedInvestment,
  LedgerReportValueField.UsdAmount,
  LedgerReportValueField.UsdAmountChange,
  LedgerReportValueField.UsdBalance,
];

const fundGroupFields = [...companyGroupFields, ...idealPnlFields, ...uncrystallizedFields];

const assetGroupFields = [
  LedgerReportValueField.AssetAmount,
  LedgerReportValueField.AssetPrice,
  ...commonAssetFields,
  ...commonPnlFields,
  LedgerReportValueField.UsdAmount,
  LedgerReportValueField.UsdAmountChange,
];

const clientGroupFields = [
  ...commonClientFields,
  LedgerReportValueField.ClientFundPercent,
  LedgerReportValueField.ClientValue,
  LedgerReportValueField.HighWaterMark,
  LedgerReportValueField.NetValue,
  ...commonPnlFields,
  ...datedPnlFields,
  ...investmentPnlFields,
  LedgerReportValueField.UnallocatedHoldings,
  LedgerReportValueField.UnallocatedInvestment,
  LedgerReportValueField.UsdBalance,
];

const fundClientGroupFields = [...clientGroupFields];

const strategyGroupFields = [
  LedgerReportValueField.AssetDeploymentPercent,
  LedgerReportValueField.AssetPortfolioPercent,
  ...commonAssetFields,
  LedgerReportValueField.ManagedCurrency,
  ...commonPnlFields,
  ...commonTurnoverFields,
  LedgerReportValueField.UsdAmount,
  LedgerReportValueField.UsdAmountChange,
  LedgerReportValueField.UsdBalance,
];

const strategyLongShortGroupFields = [
  LedgerReportValueField.AssetDeploymentPercent,
  LedgerReportValueField.AssetPortfolioPercent,
  ...commonAssetFields,
  ...commonPnlFields,
  ...commonTurnoverFields,
  LedgerReportValueField.UsdAmount,
  LedgerReportValueField.UsdAmountChange,
];

const strategyAssetGroupFields = [
  ...strategyLongShortGroupFields,
  LedgerReportValueField.AssetAmount,
  LedgerReportValueField.AssetPrice,
];

const strategyAssetLongShortGroupFields = [...strategyAssetGroupFields];

const portfolioGroupFields = [
  ...strategyGroupFields,
  LedgerReportValueField.PortfolioFundPercent,
  LedgerReportValueField.PortfolioValue,
];

const portfolioLongShortGroupFields = [...strategyLongShortGroupFields];

const portfolioAssetGroupFields = [...strategyAssetGroupFields];

const portfolioAssetLongShortGroupFields = [...strategyAssetLongShortGroupFields];

export const ledgerReportGroupsData: LedgerReportGroupsData[] = [
  {
    name: 'Overall',
    groups: [],
    values: companyGroupFields,
  },
  {
    name: 'Fund',
    groups: [LedgerReportGroupField.Fund],
    values: fundGroupFields,
  },
  {
    name: 'Fund & LP',
    groups: [LedgerReportGroupField.Fund, LedgerReportGroupField.Client],
    values: fundClientGroupFields,
  },
  {
    name: 'Asset',
    groups: [LedgerReportGroupField.Asset],
    values: assetGroupFields,
  },
  {
    name: 'LP',
    groups: [LedgerReportGroupField.Client],
    values: clientGroupFields,
  },
  {
    name: 'Portfolio',
    groups: [LedgerReportGroupField.Portfolio],
    values: portfolioGroupFields,
  },
  {
    name: 'Portfolio, split by Long/Short',
    groups: [LedgerReportGroupField.Portfolio, LedgerReportGroupField.AssetLongShort],
    values: portfolioLongShortGroupFields,
  },
  {
    name: 'Portfolio & Asset',
    groups: [LedgerReportGroupField.Portfolio, LedgerReportGroupField.Asset],
    values: portfolioAssetGroupFields,
  },
  {
    name: 'Portfolio & Asset, split by Long/Short',
    groups: [LedgerReportGroupField.Portfolio, LedgerReportGroupField.Asset, LedgerReportGroupField.AssetLongShort],
    values: portfolioAssetLongShortGroupFields,
    deprecated: true,
  },
  {
    name: 'Strategy',
    groups: [LedgerReportGroupField.Strategy],
    values: strategyGroupFields,
  },
  {
    name: 'Strategy, split by Long/Short',
    groups: [LedgerReportGroupField.Strategy, LedgerReportGroupField.AssetLongShort],
    values: strategyLongShortGroupFields,
  },
  {
    name: 'Strategy & Asset',
    groups: [LedgerReportGroupField.Strategy, LedgerReportGroupField.Asset],
    values: strategyAssetGroupFields,
  },
  {
    name: 'Strategy & Asset, split by Long/Short',
    groups: [LedgerReportGroupField.Strategy, LedgerReportGroupField.Asset, LedgerReportGroupField.AssetLongShort],
    values: strategyAssetLongShortGroupFields,
    deprecated: true,
  },
];

export const defaultLedgerReportGroupsData = ledgerReportGroupsData[0];

export const findLedgerReportGroupsDataByName = (name: string) => {
  const found = ledgerReportGroupsData.find((groupsData) => groupsData.name == name);
  if (found) {
    return found;
  }

  console.warn('Unknown groups name:', name);
  return defaultLedgerReportGroupsData;
};

export const findLedgerReportGroupsDataByGroups = (groups: readonly LedgerReportGroupField[]) => {
  const groupsSet = new Set(groups);
  const found = ledgerReportGroupsData.find(
    (groupsData) =>
      groupsData.groups.length === groupsSet.size && groupsData.groups.every((group) => groupsSet.has(group)),
  );

  if (found) {
    return found;
  }

  console.warn('Unknown groups:', groups);
  return defaultLedgerReportGroupsData;
};
